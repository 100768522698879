<template>
    <router-link
        :to="{
            name: 'technologyProductDetail',
            query: {
                id: info.id
            }
        }"
        class="product"
    >
        <el-image :src="getImg(info.img)" fit="cover"></el-image>
        <div class="content">
            <div class="name">{{ info.name }}</div>
            <div class="info">
                <div class="info-item">
                    <span class="text1">所属领域：</span>
                    <span class="text2">{{ info.fieldName }}</span>
                </div>
                <div class="info-item">
                    <span class="text1">{{ `${info.tpType == 'TECHNOLOGY' ? '成果所属人' : '产品持有人'}` }}：</span>
                    <span class="text2">{{ info.owner }}</span>
                </div>
                <div class="info-item">
                    <span class="text1">价格：</span>
                    <span class="text2" v-if="info.negotiateDirectly">面议 </span>
                    <span class="text2" v-else>{{ info.expectedPrice }}万元 </span>
                </div>
                <div class="info-item">
                    <span class="text1">交易方式：</span>
                    <span v-if="info.tpType === 'TECHNOLOGY'" class="text2">{{
                        getLabelName(info.tradingMethod, tradingMethodOptionsDemand)
                    }}</span>
                    <span v-else class="text2">{{
                        getLabelName(info.tradingMethod, tradingMethodOptionsDemands)
                    }}</span>
                </div>
            </div>
        </div>
        <div class="edit-btn" v-if="isEdit">
            <el-button v-if="info.status === 'PASS'" type="primary" size="small" plain @click.prevent="edit"
                >编辑</el-button
            >
            <el-button v-if="info.status === 'PENDING'" type="primary" size="small" plain>审核中</el-button>
            <el-button v-if="info.status === 'DENY'" type="danger" size="small" plain>已拒绝</el-button>
            <el-button size="small" plain @click.prevent="del">删除</el-button>
        </div>
        <div class="btn" v-else>
            <div class="icon-button" @click.prevent="connectKefu">
                <i class="iconfont iconfont-nav_icon_xiaoxi1"></i>
                咨询
            </div>
            <!-- <el-button type="primary" @click.prevent="postMessage" class="icon-button" size="small" plain>
                <i class="iconfont iconfont-nav_icon_xiaoxi"></i>提交留言
            </el-button> -->
        </div>
        <div class="look">
            <i class="iconfont iconfont-icon-lliulan"></i>
            <span>{{ info.view }}个人看过</span>
        </div>
    </router-link>
</template>

<script>
import comEvent from '../../mixins/comEvent';
import { tradingMethodOptionsDemands, tradingMethodOptionsDemand } from '../../utils/variables';
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tradingMethodOptionsDemands,
            tradingMethodOptionsDemand
        };
    },
    mixins: [comEvent],
    methods: {
        edit() {
            this.$router.push({
                path: '/TechnologyProductEdit',
                query: {
                    tpType: this.info.tpType,
                    id: this.info.id
                }
            });
        },
        del() {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/technologyProduct/del/${this.info.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$emit('refreash');
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>

<style lang="less" scoped>
.info {
    .flex();
    margin-top: 18px;
    flex-wrap: wrap;
    .info-item {
        width: 50%;
        padding-right: 20px;
        box-sizing: border-box;
        line-height: 32px;
        .flex();

        .text1 {
            font-size: 13px;
            color: #939599;
            display: inline-block;
            flex-shrink: 0;
            min-width: 70px;
        }

        .text2 {
            font-size: 13px;
            color: #000000;
            margin-left: 10px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
.product {
    padding: 20px;
    .flex();

    .el-image {
        width: 182px;
        height: 182px;
        flex-shrink: 0;
    }
    position: relative;
    cursor: pointer;
    border: 1px solid rgba(56,85,142,0.04);
    // border: 1px solid transparent;
    // border-bottom-color: @bg;

    &:hover {
        box-shadow: 0px 3px 12px 0px rgba(81, 81, 81, 0.1);
        border: 1px solid #01a041;
        .text2 {
            color: #01a041;
        }
        .icon-button{
            background: #01A041;
            color: #FFFFFF;
        }
    }
}
.el-image {
    width: 138px;
    height: 140px;
}
.content {
    flex-grow: 1;
    padding: 14px 70px 50px 16px;
}
.look {
    i {
        font-size: 18px;
        color: #c8c9cc;
        vertical-align: middle;
    }
    span {
        font-size: 13px;
        font-weight: 400;
        color: #818999;
        line-height: 18px;
        vertical-align: middle;
        margin: 0 3px 0 5px;
    }
    position: absolute;
    left: 220px;
    bottom: 42px;
}

.edit-btn {
    .flex();
    .el-button {
        &:first-child {
            width: 88px;
        }

        &:last-child {
            width: 56px;
        }
    }
}
.name {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    line-height: 22px;
    .ellipsis();
}

.sub {
    font-size: 14px;
    color: #939599;
    line-height: 20px;
    .ellipsis-line();
    height: 40px;
    padding: 12px 0 18px;
}

.text {
    .flex();
    font-size: 14px;
    color: #939599;
    line-height: 20px;
    span {
        &:nth-child(2) {
            flex-grow: 1;
            padding: 0 40px;
        }
    }
}

.icon-button {
    width: 134px;
    line-height: 36px;
    display: flex;
    justify-content: center;
    border-radius: 2px;
    border: 1px solid #01a041;
    box-sizing: border-box;
    color: #01a041;
    padding: 0px
}
</style>
