<template>
    <el-dialog
        v-loading="saving"
        :title="`发布供给信息-${formData.tpType === 'TECHNOLOGY' ? '技术' : '产品'}`"
        center
        :visible.sync="show"
        width="980px"
    >
        <el-form
            :model="formData"
            :rules="rules"
            ref="form"
            label-width="132px"
            label-position="right"
            size="small"
            inline
        >
            <el-form-item prop="name" :label="formData.tpType === 'TECHNOLOGY' ? '成果名称' : '产品名称'">
                <el-input
                    :placeholder="formData.tpType === 'TECHNOLOGY' ? '请输入专利名称' : '请输入专利名称'"
                    v-model="formData.name"
                ></el-input>
            </el-form-item>
            <el-form-item prop="field" label="所属领域">
                <el-select v-model="formData.field" clearable filterable placeholder="请选择" style="width: 100%">
                    <el-option v-for="item in fieldOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item
                class="block"
                prop="owner"
                :label="formData.tpType === 'TECHNOLOGY' ? '成果所属人' : '持有单位'"
            >
                <el-select
                    v-model="formData.owner"
                    multiple
                    class="suffix"
                    filterable
                    allow-create
                    default-first-option
                    :placeholder="`请输入${formData.tpType === 'TECHNOLOGY' ? '成果所属人' : '单位名称'}，按回车分割`"
                    clearable
                    popper-class="hideSelect"
                >
                </el-select>
            </el-form-item>
            <el-form-item class="block" prop="stage" label="技术成熟度" v-if="formData.tpType === 'TECHNOLOGY'">
                <el-radio-group v-model="formData.stage">
                    <el-radio v-for="item in stageOptions" :key="item.value" :label="item.value">
                        {{ item.label }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item
                class="imgBox"
                prop="img"
                :label="`${formData.tpType === 'TECHNOLOGY' ? '技术' : '产品'}附图`"
            >
                <multi-upload v-model="formData.img" :limit="6">
                    {{
                        `${
                            formData.tpType === 'TECHNOLOGY'
                                ? '请上传产品主图片，格式JPG或PNG，尺寸500x500px，大小不超过1M，最多可上传6张'
                                : '请上传产品主图片，格式JPG或PNG，尺寸500x500px，大小不超过1M，最多可上传6张'
                        }`
                    }}
                </multi-upload>
            </el-form-item>
            <el-form-item
                prop="digest"
                :label="`${formData.tpType === 'TECHNOLOGY' ? '摘要' : '产品摘要'}`"
                class="block"
            >
                <el-input
                    type="textarea"
                    v-model="formData.digest"
                    maxlength="300"
                    show-word-limit
                    :autosize="{ minRows: 6, maxRow: 6 }"
                    :placeholder="`${
                        formData.tpType === 'TECHNOLOGY' ? '请填写摘要描述，300字以内' : '请填写产品概述，300字以内'
                    }`"
                    resize="none"
                ></el-input
            ></el-form-item>
            <el-form-item
                prop="manual"
                :label="`${formData.tpType === 'TECHNOLOGY' ? '成果说明' : '产品说明'}`"
                class="block"
            >
                <rich-text v-model="formData.manual" class="block_tip"></rich-text>
            </el-form-item>
            <el-form-item
                prop="patented"
                :label="`${formData.tpType === 'TECHNOLOGY' ? '是否专利技术' : '是否专利产品'}`"
                class="block"
            >
                <!-- <el-switch v-model="formData.patented"></el-switch> -->
                <el-radio-group v-model="formData.patented">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item prop="patentNo" label="包含专利号" v-if="formData.patented" class="block">
                <!-- <el-input v-model="formData.patentNo" placeholder="请输入专利号，多个专利号按回车分割"></el-input> -->
                <el-select
                    v-model="formData.patentNo"
                    class="suffix"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    placeholder="请输入专利号，多个专利号按回车分割"
                    clearable
                    popper-class="hideSelect"
                >
                </el-select>
            </el-form-item>
            <el-form-item prop="tradingMethodId" label="交易方式" class="block" v-if="formData.tpType === 'TECHNOLOGY'">
                <el-radio-group v-model="formData.tradingMethodId">
                    <el-radio v-for="item in tradingMethodOptionsDemand" :key="item.value" :label="item.value">
                        {{ item.label }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item prop="tradingMethodId" label="交易方式" class="block" v-else>
                <el-radio-group v-model="formData.tradingMethodId">
                    <el-radio v-for="item in tradingMethodOptionsDemands" :key="item.value" :label="item.value">
                        {{ item.label }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <div class="content" style="padding-left: 30px">
                <el-form-item prop="expectedPrice" label="期望价（万元）">
                    <el-input-number
                        v-model="formData.expectedPrice"
                        :disabled="formData.negotiateDirectly"
                        @change="Price"
                        :precision="1"
                        :controls="false"
                        placeholder="请输入"
                        style="margin-right: 10px"
                    >
                    </el-input-number>
                    <el-checkbox @change="Directly" v-model="formData.negotiateDirectly">面议</el-checkbox>
                </el-form-item>
                <el-form-item prop="contact" label="联系人">
                    <el-input v-model="formData.contact"></el-input>
                </el-form-item>
            </div>
            <div class="content" style="padding-left: 40px">
                <el-form-item prop="basePrice" label="底价（万元）">
                    <el-input-number
                        v-model="formData.basePrice"
                        :min="0"
                        :precision="1"
                        placeholder="请输入"
                        :controls="false"
                    >
                    </el-input-number>
                </el-form-item>
                <el-form-item prop="phone" label="联系电话">
                    <el-input v-model="formData.phone"></el-input>
                </el-form-item>
            </div>
            <div class="content">
                <el-form-item prop="commissionType" label="佣金类型">
                    <el-radio-group v-model="formData.commissionType">
                        <el-radio v-for="item in commissionTypeOptions" :key="item.value" :label="item.value"
                            >{{ item.label }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="email" label="邮箱">
                    <el-input v-model="formData.email"></el-input>
                </el-form-item>
            </div>
            <el-form-item
                prop="commission"
                :label="`佣金${formData.commissionType == 'COMMISSION' ? '（万元）' : '（%）'}`"
            >
                <el-input v-model="formData.commission" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item prop="address" label="所在地区" class="block">
                <el-input class="suffix" placeholder="请输入所在地址" v-model="formData.address"></el-input>
            </el-form-item>
        </el-form>
        <div class="btns">
            <el-button type="info" size="small" @click="show = false">返回</el-button>
            <el-button type="primary" size="small" @click="onSave">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import {
    tradingMethodOptionsDemand,
    stageOptions,
    commissionTypeOptions,
    phonePattern,
    tradingMethodOptionsDemands
} from '../../utils/variables';
import { mapState } from 'vuex';
export default {
    name: 'TechnologyProductEdit',
    created() {
        this.$http
            .post('/setting/byFlag', { flag: 1 })
            .then(res => {
                if (res.length > 0) {
                    res.forEach(item => {
                        this.fieldOptions.push({
                            label: item.name,
                            value: item.id
                        });
                    });
                }
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });
    },
    data() {
        return {
            needChange: true,
            show: false,
            saving: false,
            formData: {},
            fieldOptions: [],
            stageOptions,
            tradingMethodOptionsDemand,
            tradingMethodOptionsDemands,
            commissionTypeOptions
        };
    },
    computed: {
        ...mapState(['userInfo']),
        types() {
            return this.$route.query.tpType;
        },
        rules() {
            return {
                name: [{ required: true, message: `请输入${this.types === 'TECHNOLOGY' ? '成果' : '产品'}名称` }],
                // field: [{ required: true, message: '请选择所属领域' }],
                // owner: [
                //     {
                //         required: true,
                //         message: `请输入${this.types === 'TECHNOLOGY' ? '成果所属人' : '产品持有人'}`
                //     }
                // ],
                // stage: [{ required: true, message: '请选择所处阶段' }],
                // img: [{ required: true, message: `请上传${this.types === 'TECHNOLOGY' ? '技术' : '产品'}附图` }],
                // digest: [{ required: true, message: `请填写${this.types === 'TECHNOLOGY' ? '摘要' : '产品概述'}` }],
                // manual: [{ required: true, message: `请输入${this.types === 'TECHNOLOGY' ? '成果' : '产品'}说明` }],
                // patented: [
                //     {
                //         required: true,
                //         message: `请选择是否专利${this.types === 'TECHNOLOGY' ? '技术' : '产品'}`,
                //         trigger: 'blur'
                //     }
                // ],
                // patentNo: [{ required: true, message: '请输入包含专利号' }],
                // tradingMethodId: [{ required: true, message: '请选择交易方式' }],
                // expectedPrice: [
                //     {
                //         required: true,
                //         validator: (rule, value, callback) => {
                //             if (!this.formData.negotiateDirectly) {
                //                 if (!this.formData.expectedPrice) {
                //                     callback(new Error('请选择期望价方式'));
                //                 } else {
                //                     callback();
                //                 }
                //             } else {
                //                 callback();
                //             }
                //         }
                //     }
                // ],
                // basePrice: [{ required: true, message: '请输入底价', trigger: 'blur' }],
                // contact: [{ required: true, message: '请输入联系人' }],
                // phone: { required: true, pattern: phonePattern, message: '请输入联系电话', trigger: 'blur' },
                // commissionType: [{ required: true, message: '请选择佣金类型' }],
                // email: [{ type: 'email', required: true, message: '请输入邮箱', trigger: 'blur' }],
                // commission: [{ required: true, message: '请输入佣金', trigger: 'blur' }],
                // address: [{ required: true, message: '请输入所在地区' }]
            };
        }
    },

    methods: {
        Price() {
            if (this.formData.expectedPrice) {
                this.formData.negotiateDirectly = false;
            }
        },
        Directly() {
            if (this.formData.negotiateDirectly == true) {
                this.$set(this.formData, 'expectedPrice', undefined);
            }
            this.$forceUpdate();
        },
        init(types) {
            this.show = true;
            this.$nextTick(() => {
                this.$refs.form.resetFields();
                this.formData = {
                    userId: this.userInfo.id,
                    tpType: types,
                    patentNo: [],
                    owner: [],
                    negotiateDirectly: false,
                    manual: '',
                    // tradingMethod: 'TRANSFER',
                    // commissionType: 'COMMISSION',
                    contact: this.userInfo.nickname,
                    phone: this.userInfo.phone,
                    email: this.userInfo.email
                };
            });
        },
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                    setTimeout(() => {
                        this.$confirm('发布成功，正在等待平台审核', '提示', {
                            confirmButtonText: '继续发布',
                            cancelButtonText: '退出',
                            type: 'warning'
                        }).then(() => {
                            setTimeout(() => {
                                this.init(this.types);
                            }, 1000);
                        });
                        // .catch(() => {
                        //     this.show = false;
                        // });
                    }, 1000);
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.formData, status: 'PENDING' };
            // console.log(data);
            data.owner = data.owner.join(',');
            // data.patentNo = data.author.join(',');

            this.saving = true;
            this.$http
                .post('/technologyProduct/save', data, { body: 'json' })
                .then(() => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.show = false;
                    this.$emit('refreash');
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/technologyProduct/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped>
.imgBox {
    width: 1000px !important;
}
.suffix {
    width: 400px !important;
    /deep/ .el-input__suffix {
        opacity: 0;
    }
}
/deep/ .el-checkbox__inner {
    border-radius: 50%;
}
.content {
    display: flex;
}
.block_tip {
    width: 800px !important;
}
/deep/ .el-dialog__header {
    padding: 17px 0px !important;
}
/deep/ .el-form {
    width: 100% !important;
    margin: 0px !important;
}
</style>
